<template>
    <div class="login">
        <div>
            <div class="login-form">
                <div class="login-form-sao"></div>
                <div class="login-form-body">
                    <div class="form-title">{{$bjggs.APP_NAME}}系统管理端</div>
                    <el-form ref="loginForm" :model="loginForm" label-width="30px" :hide-required-asterisk=true
                             :rules="rules">
                        <el-form-item prop="username">
                            <i slot="label" class="el-icon-user login-input-icon"></i>
                            <el-input type="text" placeholder="请输入用户名" v-model="loginForm.username"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <i slot="label" class="el-icon-key login-input-icon"></i>
                            <el-input show-password placeholder="请输入密码" v-model="loginForm.password"></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="login-form-button">
                        <el-button type="primary" :loading="loading" round @click="submitForm">立即登录</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-new">
<!--            <p><a href="http://www.bjggs.com.cn/" target="_blank">@技术支持：北京良安科技有限公司</a></p>-->
        </div>
    </div>
</template>

<script>
    import {ruleBuilder} from '@/util/validate'
    import {login} from '@/service/manage/multi-tenant'
    import {setAdminSessionid} from "@/util/auth";

    export default {
        name: "MultiTenantAdminLogin",
        data() {
            const {required, range} = ruleBuilder;
            this.rules = {
                username: [required(...['string', 'blur', '请输入用户名'])],
                password: [required(...['string', 'blur', '请输入密码']), range(6, 30)]
            };
            return {
                loginForm: {
                    username: '',
                    password: '',
                    tenantKey: null
                },
                loading: false,
            };
        },
        methods: {
            enterKey(event) {
                const keyCode = event.keyCode || event.which || event.charCode;
                if (keyCode === 13) {
                    this.submitForm();
                }
            },
            submitForm() {
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        login(this.loginForm).then(({data}) => {
                            this.$msgSuccess('登录成功');
                            setAdminSessionid(data.sessionId)
                            setTimeout(() => {
                                this.$router.push({
                                    name: 'MultiTenantManageList',
                                    params: {sessionId: data.sessionId}
                                })
                            }, 1000);
                        })
                            .finally(() => this.loading = false);
                    } else {
                        return false;
                    }
                });
            }
        },
        mounted() {
            document.addEventListener("keyup", this.enterKey);
            this.$once('hook:beforeDestroy', () => document.removeEventListener("keyup", this.enterKey));
        }
    }
</script>

<style lang="scss" scoped>
    .login {
        width: 100%;
        height: 100%;
        background: url('../../../assets/images/login-bg2.png') no-repeat;
        background-size: 100% 100%;
    }

    .login-form {
        width: 600px;
        height: 528px;
        background: url("../../../assets/images/login-form-bg.png") no-repeat;
        position: fixed;
        top: 52%;
        left: 70%;
        margin-top: -264px;
        margin-left: -300px;
    }

    .login-form-sao {
        width: 493px;
        height: 493px;
        background: url("../../../assets/images/login-form-sao1.png") no-repeat;
        top: 11px;
        position: absolute;
        left: 48px;
        -webkit-transform: rotate(360deg);
        animation: rotation 10s linear infinite;
        -moz-animation: rotation 10s linear infinite;
        -webkit-animation: rotation 10s linear infinite;
        -o-animation: rotation 10s linear infinite;
    }

    @-webkit-keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    .login-form-body {
        position: absolute;
        width: 260px;
        height: 276px;
        top: 112px;
        left: 165px;
    }

    .form-title {
        height: 96px;
        line-height: 96px;
        color: #eff309;
        font-size: 20px;
        width: 100%;
        text-align: center;
    }

    .login-form-button {
        height: 50px;
        line-height: 50px;
        text-align: center;
    }

    .footer-new {
        width: 100%;
        height: 66px;
        line-height: 66px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        position: fixed;
        bottom: 0;
    }

    .footer-new p {
        display: block;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        text-align: center;
        font-size: 20px;
    }

    .footer-new a {
        color: #fff;
        font-family: 幼圆;
    }
</style>
